/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import BackgroundImg from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import RenderForm from 'gatsby-plugin-cloudofertas'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// scss
import './styles/gestao-de-residuos.scss'

// Components
import Pagina from '../components/Pagina'
import FloatingItem from '../components/FloatingItem/floating-icon'
// import BannerLogin from '../components/BannerLogin'

// Assets
// import icon1 from '../images/gestao-de-residuos/icon1.svg'
import icon2 from '../images/gestao-de-residuos/icon2.svg'
import icon3 from '../images/gestao-de-residuos/icon3.svg'
import icon4 from '../images/gestao-de-residuos/icon4.svg'
import icon5 from '../images/gestao-de-residuos/caminhao.svg'
import icon6 from '../images/gestao-de-residuos/icon6.svg'
import reciclar from '../images/gestao-de-residuos/reciclar.svg'
import helmet from '../images/gestao-de-residuos/helmet.svg'

const Paralax = ({ paralax, paralaxMobile }) => {
  const image = getImage(paralax)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(paralaxMobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  const intl = useIntl()

  return (
    <>
      <div>
        <BackgroundImg
          {...bgImage}
          Tag="div"
          className="paralaxImage d-lg-block d-none"
        >
          <div className="container h-100">
            <div className="container h-100 d-flex justify-content-lg-start justify-content-center ">
              <div className=" h-100 d-flex align-items-center">
                <div className="w-100">
                  <h1 className="pb-4 text-white">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.paralax.title' }))}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="gradient-blue-to-green"></div>
        </BackgroundImg>
        <BackgroundImg
          {...bgImageMobile}
          Tag="div"
          className="paralaxImage d-lg-none d-block"
        >
          <div className="container h-100">
            <div className="container h-100 d-flex ">
              <div className=" h-100 d-flex align-items-center pt-5">
                <div className="w-100 pt-5 pl-4">
                  <h1 className="pb-4 text-white">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.paralax.title' }))}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="gradient-blue-to-green"></div>
        </BackgroundImg>
      </div>
    </>
  )
}

const MoldeDesempenho = props => (
  <>
    <div className="cardIconYellow col-lg-auto col-auto mt-1 mb-lg-4 mb-2 justify-content-lg-start justify-content-center">
      <div className="text-lg-center d-flex align-items-center d-flex justify-content-center">
        <img src={props.icon} alt="Icone do Card" className={props.width} />
      </div>
    </div>
    <div className="col-lg-6 col-auto mt-lg-4 d-flex align-items-center justify-content-lg-start justify-content-center p-0 m-0">
      <p className="line-height-normal text-white w-100 text-uppercase font-size-09 font-Spinnaker">{props.text}</p>
    </div>
  </>
)

const SolucaoCompleta = ({ terra }) => {
  const intl = useIntl()

  return <>
    <section className="full  m-0 py-5 py-lg-0 sectionSolucao">
      <div className="container p-lg-5 px-0">
        <div className="row m-0 p-0 align-items-center px-4 mx-0">
          <div className="col-lg-6 ml-0 mb-lg-0 mb-3 px-0 px-lg-0">
            <h2 className="color-blue my-3">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.solucao_completa.title' }))}</h2>
            <p className="font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.solucao_completa.p1' }))} </p>
            <p className="font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.solucao_completa.p2' }))}</p>
            <p className="font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.solucao_completa.p3' }))}</p>
            <p className="font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.solucao_completa.p4' }))}</p>
          </div>
          <div className="col terra px-0 px-lg-3 d-lg-block d-none">
            <GatsbyImage
              image={terra}
              className="imagem-residuos"
              alt="Imagem do planeta terra" />
          </div>
        </div>
      </div>
    </section>
  </>
}

const PorqueLwart = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.section ref={ref} animate={controls} className="overflow-auto bg-lwart-green4 full m-0">
      <div className="container pt-5 pr-lg-5 pl-lg-5 pb-5 pb-0 px-0">
        <div className="row m-0 p-0 h-100 align-items-center px-4 mx-0">
          <div className="col-lg-4 text-white align-items-center px-0 px-lg-3">
            <motion.h2 initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 }
              }} className="text-lwart-yellow pb-3 pr-2">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.text.title' }))}</motion.h2>
            <motion.p initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }} className="text-white line-height-normal">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.text.p1' }))}
            </motion.p>
            <motion.p initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }} className="text-white line-height-normal">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.text.p2' }))}
            </motion.p>
            <motion.p initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }} className="text-white line-height-normal">
              <a href='mailto:gestaderesiduos@lwart.com.br' className='text-white'>{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.text.link' }))}</a>
            </motion.p>
          </div>
          <div className="col-lg-8 d-flex">
            <div className="row justify-content-center align-items-center px-0 px-lg-3">
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block">
                <MoldeDesempenho icon={icon2} width="w-90" text={parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.icones.solucao' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block margin-top-veiculo">
                <MoldeDesempenho icon={icon5} width="w-80" text={parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.icones.veiculos' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block">
                <MoldeDesempenho icon={helmet} width="w-60" text={parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.icones.expert' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block margin-top-online">
                <MoldeDesempenho icon={icon6} width="w-70" text={parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.icones.online' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block margin-bottom-custom">
                <MoldeDesempenho icon={icon4} width="w-70" text={parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.icones.garantia' }))} />
              </motion.div>
              <motion.div initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 50 }
                }} className="col-lg-6 col-6 d-lg-flex d-block">
                <MoldeDesempenho icon={icon3} width="w-70" text={parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.porque_lwart.icones.relacionamento' }))} />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

const MoldeSolucoes = props => {
  const intl = useIntl()

  return (
    <>
      <motion.div initial="hidden"
        transition={{ duration: 1, delay: 0.5 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 }
        }} className="w-100">
        <div className='container'>
          <div className='row row-cols-lg-3 row-cols-2'>
            <Link to='/gestao-total-de-residuos/'>
              <div className='col d-flex justify-content-center'>
                <div className='cardSolucoes '>
                  <p>{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.molde_solucoes.molde1' }))}</p>
                </div>
              </div>
            </Link>
            <Link to='/coleta-e-destinacao-de-residuos/'>
              <div className='col d-flex justify-content-center'>
                <div className='cardSolucoes '>
                  <p>{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.molde_solucoes.molde2' }))}</p>
                </div>
              </div>
            </Link>
            <Link to='/assessoria-ambiental/'>
              <div className='col d-flex justify-content-center pt-lg-0 pt-3'>
                <div className='cardSolucoes '>
                  <p>{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.molde_solucoes.molde3' }))}</p>
                </div>
              </div>
            </Link>
            <Link to='/scrap-produtivo-e-descaracterizacao/'>
              <div className='col d-flex justify-content-center pt-lg-5 pt-3'>
                <div className='cardSolucoes '>
                  <p>{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.molde_solucoes.molde4' }))}</p>
                </div>
              </div>
            </Link>
            <Link to='/transbordo-de-residuos/'>
              <div className='col d-flex justify-content-center pt-lg-5 pt-3'>
                <div className='cardSolucoes '>
                  <p>{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.molde_solucoes.molde5' }))}</p>
                </div>
              </div>
            </Link>
            <a href='https://sga.lwart.com.br/' target="_blank" rel="noreferrer">
              <div className='col d-flex justify-content-center pt-lg-5 pt-3'>
                <div className='cardSolucoes '>
                  <p>{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.molde_solucoes.molde6' }))}</p>
                </div>
              </div>
            </a>

          </div>
        </div>
      </motion.div>
    </>
  )
}

const ConhecaSolucoes = (data) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  const image = getImage(data.paralax2)
  const bgImage = convertToBgImage(image)
  // const imageMobile = getImage(data.paralax2Mobile)
  // const bgImageMobile = convertToBgImage(imageMobile)
  return (
    <>
      <motion.div ref={ref} animate={controls}>
        <BackgroundImg
          {...bgImage}
          Tag="div"
          className="paralaxImage"
        >
          <div className="container h-100 w-100 d-flex justify-content-center">
            <div className="row h-100 mw-100">
              <div className='d-flex justify-content-center align-items-center col-12'>
                <h2 className="text-white text-center">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.conheca_solucoes.title' }))}</h2>
              </div>
              <div>
                <motion.div initial="hidden"
                  transition={{ duration: 1.5 }}
                  variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: -150 }
                  }}>
                </motion.div>
              </div>
              <MoldeSolucoes />
            </div>
          </div>
        </BackgroundImg>
      </motion.div>
    </>
  )
}

const GestaoResiduos = ({ celular }) => {
  const intl = useIntl()

  return (
    <>
      <section className="bg-lwart-lightblue2 full m-0 cracha pt-5">
        <div className="container pb-lg-0 px-0">
          <div className="row m-0 px-4 pb-5">
            <div className="col-lg-7 my-0 px-0 pl-lg-5 text-lg-left text-white pb-2 pt-4">
              <div>
                <div className='borderSGA'>
                  <h2 className="text-white pl-2">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.gestao_residuos.title' }))}</h2>
                </div>
                <p className="text-white py-2">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.gestao_residuos.p1' }))}
                </p>
                <p className="text-white">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.gestao_residuos.p2' }))}
                </p>
                <p className="text-white pt-2">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.gestao_residuos.p3' }))}
                </p>
                <p className='text-white font-weight-bold'>{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.gestao_residuos.p4' }))}</p>
                <label className="w-100 my-4">
                  <a href="https://sga.lwart.com.br/" target="_blank" rel="noopener noreferrer" className="font-Spinnaker acesseAgora">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.gestao_residuos.link' }))}</a>
                </label>
              </div>
            </div>
            <div className="col-lg-5 celular d-lg-block d-none">
              <GatsbyImage
                image={celular}
                alt="Imagem de um celular"
                className='celularImg' />
            </div>
          </div>
        </div>
      </section>
      <div className="gradient-blue-to-green"></div>
    </>
  )
}

const GestaoDeResiduos = ({ data }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)
  const intl = useIntl()
  if (intl.locale === 'en') {
    data.cloudOfertasForm.formTypeField[0].label = 'Name'
    data.cloudOfertasForm.formTypeField[2].label = 'Phone*'
  } else if (intl.locale === 'es') {
    console.log('caiu')
    data.cloudOfertasForm.formTypeField[0].label = 'Nombre'
    data.cloudOfertasForm.formTypeField[1].label = 'Correo electrónico'
    data.cloudOfertasForm.formTypeField[2].label = 'Telefono*'
  }

  console.log(data.cloudOfertasForm)

  return <>
    <Pagina pagina={data.pagina} />
    <FloatingItem link='mailto:gestaderesiduos@lwart.com.br' />
    <Paralax paralax={data.paralax.childImageSharp.gatsbyImageData} paralaxMobile={data.paralaxMobile.childImageSharp.gatsbyImageData} />
    <SolucaoCompleta terra={data.terra.childImageSharp.gatsbyImageData} />
    <ConhecaSolucoes paralax2={data.paralax2.childImageSharp.gatsbyImageData} paralax2Mobile={data.paralax2Mobile.childImageSharp.gatsbyImageData} />
    <PorqueLwart />
    <GestaoResiduos celular={data.celular.childImageSharp.gatsbyImageData} />
    <section className='full m-0 py-5 py-lg-0'>
      <div className="container p-lg-5 px-0">
        <h2 className="color-blue my-3 text-center">{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.gestao_de_residuos_main.title' }))}</h2>
      </div>
    </section>
    <div className='mb-5'>
      {data.cloudOfertasForm.formTypeField && submitSuccess === false &&
        <RenderForm
          disabledButton={disableBtn}
          formData={data.cloudOfertasForm}
          setSuccessSubmit={setSubmitSuccess}
          placeholder={true}
          containerStyle='container'
          rowStyle='row'
          inputStyle='custom-form py-lg-0 py-2'
          btnContainerStyle='custom-form col-lg-3 col-12 py-lg-0 pt-2'
          btnStyle='btn btn-block'
          btnName={ intl.locale === 'pt' ? 'Enviar' : intl.locale === 'es' ? 'Mandar' : 'Send'}
        />
      }
      {submitSuccess &&
        <div className='container mt-0'>
          <div className="text-center font-Spinnaker">
            <p>{parse(intl.formatMessage({ id: 'paginas.gestao_de_residuos.gestao_de_residuos_main.submit_success' }))}</p>
          </div>
        </div>
      }
    </div>
  </>
}

export default GestaoDeResiduos

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/gestao-de-residuos/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  terra: file(relativePath: {eq: "gestao-de-residuos/terra.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  celular: file(relativePath: {eq: "gestao-de-residuos/celular.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralax: file(relativePath: {eq: "gestao-de-residuos/banner-gestao-residuos.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(
    relativePath: {eq: "gestao-de-residuos/gestao-residuos.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralax2: file(relativePath: {eq: "gestao-de-residuos/nossas-solucoes.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralax2Mobile: file(relativePath: {eq: "gente/paralax-2-mobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  cloudOfertasForm(name: {eq: "CONTATO"}) {
    id
    formTypeField {
      name
      type
      required
      style
      length
      label
      formLov {
        formLovData {
          id
          value
          createdDate
        }
      }
    }
  }
}
`
